import Lightning from './svgs/lightning'
import Jamstack from './svgs/jamstack'
import Wifi from './svgs/wifi'
import Lighthouse from './svgs/lighthouse'
import Plus from './svgs/plus'
import Envelope from './svgs/envelope'
import Scroll from './svgs/scroll'

const features = [
  {
    text: 'Life changing therapy',
    icon: Lightning,
  },
  {
    text: 'Playable Games',
    icon: Jamstack,
  },
  {
    text: 'Readable Blog',
    icon: Wifi,
  },
  {
    text: 'Functional Questions Page',
    icon: Plus,
  },
  {
    text: 'Questionable Icon Useage',
    icon: Envelope,
  },
  {
    text: 'Gratuitous Lists',
    icon: Scroll,
  },
]

const Features = () => (
  <div className="features">
    {features.map(({ text, icon: Icon }) => (
      <div className="feature" key={text}>
        {Icon && <Icon height={24} width={24} />}
        <h4>{text}</h4>
      </div>
    ))}
  </div>
)

export default Features
