const Jamstack = props => <svg
  width="24"
  height="24"
  fill="none"
  stroke="currentColor"
  strokeLinecap="round"
  strokeLinejoin="round"
  strokeWidth="1.5"
  shapeRendering="geometricPrecision"
  viewBox="0 0 24 24"
  {...props}
>
  <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
</svg>;

export default Jamstack;
