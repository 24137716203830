const Wifi = props => <svg
  viewBox="0 0 24 24"
  stroke="currentColor"
  strokeWidth="1.5"
  strokeLinecap="round"
  strokeLinejoin="round"
  fill="none"
  shapeRendering="geometricPrecision"
  {...props}
>
  <path d="M5 12.55a11 11 0 0114.08 0" />
  <path d="M1.42 9a16 16 0 0121.16 0" />
  <path d="M8.53 16.11a6 6 0 016.95 0" />
  <path d="M12 20h.01" />
</svg>;

export default Wifi;
