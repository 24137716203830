const Plus = props => <svg
  viewBox="0 0 24 24"
  width="24"
  height="24"
  stroke="currentColor"
  strokeWidth="1.5"
  strokeLinecap="round"
  strokeLinejoin="round"
  fill="none"
  shapeRendering="geometricPrecision"
  {...props}
>
  <path d="M23 6l-9.5 9.5-5-5L1 18" />
  <path d="M17 6h6v6" />
</svg>;

export default Plus;
