const Lightning = props => <svg
  width="24"
  height="24"
  fill="none"
  stroke="currentColor"
  strokeLinecap="round"
  strokeLinejoin="round"
  strokeWidth="1.5"
  shapeRendering="geometricPrecision"
  viewBox="0 0 24 24"
  {...props}
>
  <path d="M13 2L3 14h9l-1 8 10-12h-9l1-8z"></path>
</svg>;

export default Lightning;
