import Link from 'next/link'
import Header from '../components/header'
import ExtLink from '../components/ext-link'
import Features from '../components/features'
import GitHub from '../components/svgs/github'
import sharedStyles from '../styles/shared.module.css'
import Image from 'next/image'

const Index = () => (
  <>
    <Header titlePre="Home" />
    <div className={sharedStyles.layout}>
      <div className={sharedStyles.centerImage}>
        <Image
          src="/billy-happy1.png"
          height={100}
          width={55}
          alt="Billy avatar"
        />
      </div>
      <h3>I'm Alex and these are my things</h3>
      <Features />
    </div>
  </>
)

export default Index
