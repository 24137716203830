const Scroll = props => <svg
  viewBox="0 0 24 24"
  width="24"
  height="24"
  stroke="currentColor"
  strokeWidth="1.5"
  strokeLinecap="round"
  strokeLinejoin="round"
  fill="none"
  shapeRendering="geometricPrecision"
  {...props}
>
  <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z" />
  <path d="M14 2v6h6" />
  <path d="M16 13H8" />
  <path d="M16 17H8" />
  <path d="M10 9H8" />
</svg>;

export default Scroll;
